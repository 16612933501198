import * as React from "react"
import { Link } from "gatsby"

const Layout = ({ location, title, subtitle, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  header = (
    <span className="header-container">
			<h1 className="main-heading">
        <Link to="/">{title} | <span className="subtitle">{subtitle}</span></Link>
      </h1>
			<span className="header-menu">
				<a href="https://github.com/kabartolo" target="_blank" rel="noopener noreferrer"><i className={`header-icon devicon-github-plain colored`}></i></a>
				<a href="https://www.linkedin.com/in/kabartolo/" target="_blank" rel="noopener noreferrer"><i className={`header-icon devicon-linkedin-plain colored`}></i></a>
				<Link to="/about">About</Link>
			</span>
		</span>
    )

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      <main>{children}</main>
      <footer className="global-footer">
        <span className="footer-container">
					© {new Date().getFullYear()}, Built with
	        {` `}
	        <a href="https://www.gatsbyjs.com" target="_blank" rel="noopener noreferrer">Gatsby</a> and the <a href="https://developers.forem.com/api" target="_blank" rel="noopener noreferrer">DEV API</a>
				</span>
      </footer>
    </div>
  )
}

export default Layout
